<template>
  <div class="container">
    <div class="fadein"></div>
    <div class="margin-container">
    <img src="@/assets/procrealms-text.png">
      <!-- <h1>Procedural Realms</h1> -->
      <div class="about">
        <h2>A text-based exploration and survival game</h2>
        <p class="description">Inspired by turn-based JRPG games, Diablo-esque loot grinders, and cooperative base-building games such as Terraria, Starbound, Valheim, and Stardew Valley, Procedural Realms is a text-based RPG played right in the browser.  The game combines classic turn-based RPG combat with crafting and survival game elements in a giant procedurally-generated world that players can build and explore.  Join other players in conquering a world rich with resources to harvest, monsters to slay, dungeons to explore, and treasures to claim!</p>
        <ul>
          <li>Crawl dungeons, discover randomized loot and equipment, or craft and enchant your own</li>
          <li>Customize your character by learning new skills as you progress with the flexible skill system</li>
          <li>Choose your playstyle: solo PvE combat, group dungeons, or craft and build a base with friends</li>
          <li>Build a farm, breed livestock, and produce goods to sell at the auction house or in your own shop</li>
          <li>Craft weapons and armor, brew potions, cook, build a shelter, go fishing, or dig for buried treasure</li>
          <li>Enjoy casual gameplay with turn-based combat, simple game mechanics, and a friendly environment</li>
          <li>Over 100 combat abilities and 10 crafting skills give you endless customization and play style options</li>
          <li>Fast character creation and a few quick interactive tutorials will have you playing quickly</li>
        </ul>
        <p>Click the Play Now button below or use your favorite MUD client to connect to:</p>
        <p class="hostname">proceduralrealms.com 3000</p>
      </div>
      <div class="links">
        <a href="http://play.proceduralrealms.com">Play Now</a>
        <a href="https://discord.gg/rRFEjc6vV9">Join Discord</a>
      </div>
    </div>
    <div class="fadeout"></div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style lang="less">
.container {
  text-align: center;
  img {
    margin-top: 30px;
    width: ~"calc(100vw - 40px)";
    max-width: 1000px;
    margin-bottom: 30px;
  }
  h1 {
    z-index: 100;
    position: relative;
    text-align: center;
    padding: 5vh 0 5vh 0;
    margin: 0;
    font-family: 'Crimson Text', serif;
    font-size: 80px;
    line-height: 90px;
    color: #195f19;
    text-shadow: 0 0 1px #cf3;
  }

  h2 {
    position: relative;
    text-align: center;
    padding: 0;
    margin: 10px 0 0 0;
    font-family: 'Kanit', sans-serif;
    font-size: 24px;
    color: #ffffff;
  }

  .about {
    z-index: 100;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    max-width: 1000px;
    font-size: 16px;
    padding: 5px 10px;
    color: #fff;
    border-radius: 4px;
    font-family: 'Kanit', sans-serif;
    background-color: darken(#2faf2f, 20%);
    p {
      margin: 10px 5px;
      line-height: 22px;
      &.description {
        text-align: left;
        margin-left: 20px;
        margin-right: 10px;
      }
    }
    .hostname {
      margin: 0px 0 15px 0;
      padding: 0;
      font-size: 24px;
      font-weight: bold;
    }
    ul {
      padding: 0;
      margin: 0 0 0 20px;
      text-align: left;
      li {
        line-height: 22px;
      }
    }
  }

  .links {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    margin: 5vh auto;
    max-width: 600px;
    z-index: 100;
    a {
      display: inline-block;
      text-decoration: none;
      padding: 5px 20px;
      margin: 10px 0;
      border-radius: 4px;
      font-family: 'Kanit', sans-serif;
      border: 1px solid lighten(#2faf2f, 5%);
      font-size: 35px;
      color: #f8f8f8;
      background-color: #2faf2f;
      transition: 0.5s;
      opacity: 0.8;
      &:hover {
        background-color: lighten(#2faf2f, 5%);
        color: #ffffff;
        box-shadow: 0 0 10px #cf3;
        opacity: 1;
      }
    }
  }

}
</style>