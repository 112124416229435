<template>
  <div class="container">
    <div class="fadein"></div>
    <div class="margin-container">
      <h1>Procedural Realms</h1>
      <h2>A text-based exploration and survival game</h2>
      <div class="about">
        <p>Procedural Realms is a text-based RPG played right in the browser.  The game combines elements of turn-based RPG combat with survival and crafting in a giant procedurally-generated world.  Join other players</p>
      </div>
    </div>
    <div class="fadeout"></div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style lang="less">
.container {
  .margin-container {
    position: relative;
  }
}
</style>